import React, { useState } from "react"
import TMHBanner from "../../components/TMHBanner/TMHBanner"
import TMHHeader from "../../components/TMHHeader/TMHHeader"
import TMHFooter from "../../components/TMHFooter/TMHFooter"
import "./ContactPage.scss"
import { useTitle } from 'react-use'
import validator from 'validator'

export default function ContactPage() {
    useTitle("Contact Page - Roku Remote App")

    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")

    async function handleSubmit() {
        setError("")
        setSuccess("")

        if (name === "") {
            setError("Your name is required!")
            return
        } else if (email === "") {
            setError("Email is required!")
            return
        } else if (!validator.isEmail(email)) {
            setError("Email Field is Invalid")
            return
        } else {
            setIsLoading(true)

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Cache-Control", "no-cache");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    "email": email,
                    "subject": subject,
                    "message": message,
                    "name": name,
                    "directly": "RokuRemoteApp"
                }),
            };

            fetch("https://themoviehouse.tv/api/users/contact_us", requestOptions)
                .then((response) => {
                    return new Promise((resolve) => response.json()
                        .then((json) => resolve({
                            status: response.status,
                            ok: response.ok,
                            json
                        })))
                }).then(
                    (result) => {
                        if (result.ok) {
                            setSuccess(result.json.message)
                            setName("")
                            setEmail("")
                            setMessage("")
                            setSubject("")
                        }
                        else {
                            setError(result.json.message)
                        }
                        setIsLoading(false)
                    },
                    (error) => {
                        console.log(error)
                        setError(error.toString())
                        setIsLoading(false)
                    }
                )
        }
    }
    return (
        <>
            <TMHBanner />
            <div className="contact-container">
                <TMHHeader />
                <div className="contact-title">For any inquiries please email</div>
                <div style={{ color: "red" }}>{error}</div>
                <div style={{ color: "green" }}>{success}</div>
                <input
                className="contact-input"
                    placeholder="Your Name *"
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                />
                <input
                className="contact-input"
                    placeholder=" Email *"
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                />
                <input
                className="contact-input"
                    placeholder="Subject"
                    value={subject}
                    onChange={({ target }) => setSubject(target.value)}
                />
                <div className="contact-text-area">
                    <textarea
                        placeholder="Message"
                        value={message}
                        onChange={({ target }) => setMessage(target.value)}
                    />
                </div>

                <p className="contact-mandatory">*mandatory field </p>
                <button className="contact-send-button" onClick={() => handleSubmit()}>SEND</button>
                <div className="directly-block">
                    <p className="contact-email-title">Email Directly:</p>
                    <p className="contact-email">support@SimpleMovieRemote.com</p>
                </div>
                <TMHFooter />
            </div>
        </>
    )
}