import React from "react"
import TMHBanner from "../../components/TMHBanner/TMHBanner"
import TMHHeader from "../../components/TMHHeader/TMHHeader"
import TMHFooter from "../../components/TMHFooter/TMHFooter"
import "./TermsOfUsePage.scss"
import { useTitle } from 'react-use'
import TermsData from "./data.json"

export default function TermsOfUsePage() {
    useTitle("Terms of Use  - Roku Remote App")

    return (
        <>
            <TMHBanner />
            <div className="terms-of-use-container">
                <TMHHeader />
                <label className="title-label">Terms of Use</label>
                <div className="page-content">
                    <div className="desc">
                        {TermsData?.description.map((desc, desIndex) => (
                            <p key={"des_" + desIndex} dangerouslySetInnerHTML={{ __html: desc }}></p>
                        ))}
                    </div>
                    {TermsData?.terms.map((term, index) => (
                        <div className="term" key={index}>
                            <p className="term-header">
                                <span style={{ marginRight: "4px" }}> {index + 1}.</span>{" "}
                                {term.header}
                            </p>
                            {term?.paragraph.map((text, termIndex) => (
                                <p className="term-text" key={"term_" + termIndex} dangerouslySetInnerHTML={{ __html: text }}></p>
                            ))}
                        </div>
                    ))}
                </div>
                <TMHFooter />
            </div>
        </>
    )
}