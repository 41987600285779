import React from "react"
import "./TMHHeaderStyles.scss"
import LogoImg from "./logo.png"

export default function TMHHeader() {
    return (
        <div className="tmh-header">
            <img className="header-logo" src={LogoImg} alt="Roku Remote logo" />
            <label className="header-title">Simple <b>Roku Remote +</b></label>
        </div>
    )
}