import React from "react"
import TMHBanner from "../../components/TMHBanner/TMHBanner"
import TMHHeader from "../../components/TMHHeader/TMHHeader"
import TMHFooter from "../../components/TMHFooter/TMHFooter"
import "./LandingPage.scss"
import GooglePlayIcon from "./google-play.png"
import AppStoreIcon from "./app-store.png"
import { useTitle } from 'react-use'

export default function LandingPage() {
    useTitle("Home Page - Roku Remote App")

    return (
        <>
            <TMHBanner />
            <div className="landing-container">
                <TMHHeader />
                <label className="label-1">The Simple Roku Remote + is the best ad free mobile remote for your Roku device. Set up and sync to your Roku device in seconds! </label>
                <label className="label-2">Download now</label>
                <div className="platform-block">
                    <a href="https://play.google.com/store/apps/details?id=com.rokuremote" target="_blank" rel="noreferrer">
                        <button className="platform-logo" >
                            <img src={GooglePlayIcon} alt="gg-icon" />
                        </button>
                    </a>
                    <button className="platform-logo" disabled >
                        <img src={AppStoreIcon} alt="appstore-icon" />
                    </button>
                    <label className="label-3">Coming Soon on App Store</label>
                </div>
                <TMHFooter />
            </div>
        </>
    )
}