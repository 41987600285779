export default async function getUserLocationFromAPI() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return new Promise((resolve) => resolve('US'))
    }

    var myHeaders = new Headers();
    myHeaders.append("Cache-Control", "no-cache");
    const response = await fetch("https://themoviehouse.tv/api/users/get_country_code", {
        method: 'GET',
        headers: myHeaders
    })

    return new Promise((resolve) => response.json()
        .then((json) => {
            if (response.ok) {
                resolve(json.data)
            }
            else {
                resolve('US')
            }
        }))
}
