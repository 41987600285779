import { HashRouter, Route, Routes } from "react-router-dom"
import LandingPage from "./pages/LandingPage/LandingPage"
import TermsOfUsePage from "./pages/TermsOfUsePage/TermsOfUsePage"
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage"
import ContactPage from "./pages/ContactPage/ContactPage"

function App() {
  return (
    <HashRouter>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="*" element={<LandingPage />} />
      <Route path="/terms-of-use" element={<TermsOfUsePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/contact" element={<ContactPage />} />
    </Routes>
  </HashRouter>
  );
}

export default App;
